import * as React from "react"
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import {SEO} from "../components/seo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogPreview from "../components/blogPreview";

const Blog = ({data}) => {
    const blogPosts = data.allContentfulBlogPost.edges;
    return (
        <div>
            <Helmet>
                <title>Inter Protocol - Blog</title>
            </Helmet>
            <Header/>
            <div className={'section pt-40'}>
                <div className={'container container-slim'}>
                    <h1 className={'mb-14 md:mb-20'}>Blog</h1>
                    <div className={'grid gap-14 md:grid-cols-2 lg:grid-cols-3 md:gap-14'}>
                        {blogPosts.map((post, index) => {
                            return(
                                <div className={'pb-14'} key={index}>
                                    <BlogPreview post={post.node}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={'section footer-section'}>
                <Footer/>
            </div>
        </div>
    )
}

export default Blog

export const Head = () => (
    <SEO />
)

export const query = graphql`
  query {
    allContentfulBlogPost(sort: {order: DESC, fields: publishedAt}) {
      edges {
        node {
          id
          title
          slug
          body {
            raw
          }
          excerpt
          image {
            title
            url
          }          
          thumbnail {
            url
            title
          }
          publishedAt
        }
      }
    }
  }
`
